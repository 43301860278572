import { raffle } from 'data/data.json';
import zipcodes from 'zipcodes';
import {
  TICKET_PURCHASE,
  PURCHASER_FORM,
  CONFIRM_ORDER,
  IP_CHECK,
  pageOrder
} from 'constants/pages';

export const getFeaturedCategoryPrizes = categoryPrizePages => {
  let featuredCategoryPrizes = [];
  let featuredItems = [];

  if (categoryPrizePages) {
    categoryPrizePages.forEach(categoryPrizePage => {
      if (categoryPrizePage.categoryBlocks) {
        featuredItems = categoryPrizePage.categoryBlocks
          .filter(block => block.blockType === 'prize_block')
          .slice(0, 3)
          .map(_featuredItem => {
            // to avoid [no-param-reassign] error decouple instance
            const featuredItem = Object.assign({}, _featuredItem);
            // assign a property
            featuredItem.pageUrl = categoryPrizePage.pageName.toLowerCase().replace(/\s/g, '-');
            // return new instance
            return featuredItem;
          });
      }

      featuredCategoryPrizes = [...featuredCategoryPrizes, ...featuredItems];
    });
  }

  return featuredCategoryPrizes;
};

export const isPhoneNumber = value => {
  if (!value) {
    return true;
  }
  const extractNumbers = (value && value.match(/\d+/g)) || [];
  return extractNumbers.join('').length === 10;
};

// denormalizes two immutable lists by column name
export const denormalize = (a, aCode, b, bCode) => {
  const hashMap = b.groupBy(item => item.get(bCode || aCode)).map(item => item.first());
  const result = a.map(item => {
    const joinId = item.get(aCode);
    const lookupValue = hashMap.get(joinId);
    return item.merge(lookupValue);
  });
  return result;
};

// Rebuild this function completely
// lookup address in object by string
export const byString = (o, s) => {
  if (!o || !s) return undefined;

  let object = o;
  const address = s
    .replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    .replace(/^\./, '') // strip a leading dot
    .split('.'); // convert to array
  for (let i = 0, n = address.length; i < n; ++i) {
    const key = address[i];
    // Sometimes Yup does not properly clear out errors in FieldArray validation, leaving an undefined
    // TODO: fix error in Yup, or filter objects?
    if (!object) return undefined;
    if (key in object) {
      object = object[key];
    } else {
      return undefined;
    }
  }
  return object;
};

export const scrollToTop = () => {
  try {
    window.scroll({
      behavior: 'smooth',
      top: 0
    });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(0, 0);
    } else {
      throw err;
    }
  }
};

export const setTitle = (draws, stage, drawStage) => {
  switch (pageOrder[stage]) {
    case IP_CHECK:
      return 'Region Check';
    case TICKET_PURCHASE:
      return draws.getIn([drawStage, 'description']);
    case PURCHASER_FORM:
      return 'Purchaser Information';
    case CONFIRM_ORDER:
      return 'Your Order Summary';
    default:
      return 'Thank You!';
  }
};

export const generateBestDealText = (bonusTickets, priceSavings) => {
  const text = [];
  if (priceSavings > 0) text.push(`save $${priceSavings}`);
  if (bonusTickets > 0) text.push(`get ${bonusTickets} extra ticket${bonusTickets > 1 ? 's' : ''}`);

  if (text.length === 0) return '';

  const result = text.join(' and ');

  return `${result[0].toUpperCase()}${result.substring(1)}! `;
};

export const generateBundleDescription = tickets =>
  tickets.map(ticket => `${ticket.get('quantity')}x ${ticket.get('description')}`).join(', ');

export const generateMaxPackDescription = (ordered, tickets) =>
  tickets.map(ticket => `${ordered}x ${ticket.get('description')}`).join(', ');

export const camelCaseToSentence = text => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

// Phone number validation helpers
export const formatPhoneNumber = phoneNumber =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');

// Postal code and city validation helpers (may need to be moved as helpers file is cleaned up)
// Provinces
export const provinces = [
  { value: 'alberta', label: 'Alberta' },
  { value: 'british_columbia', label: 'British Columbia' },
  { value: 'manitoba', label: 'Manitoba' },
  { value: 'new_brunswick', label: 'New Brunswick' },
  { value: 'newfoundland_and_labrador', label: 'Newfoundland & Labrador' },
  { value: 'northwest_territory', label: 'Northwest Territory' },
  { value: 'nova_scotia', label: 'Nova Scotia' },
  { value: 'nunavut_territory', label: 'Nunavut Territory' },
  { value: 'ontario', label: 'Ontario' },
  { value: 'prince_edward_island', label: 'Prince Edward Island' },
  { value: 'quebec', label: 'Quebec' },
  { value: 'saskatchewan', label: 'Saskatchewan' },
  { value: 'yukon', label: 'Yukon Territory' }
];

// States
export const states = [
  { value: 'alaska', label: 'Alaska' },
  { value: 'alabama', label: 'Alabama' },
  { value: 'alabama', label: 'Alabama' },
  { value: 'arkansas', label: 'Arkansas' },
  { value: 'arizona', label: 'Arizona' },
  { value: 'california', label: 'California' },
  { value: 'colorado', label: 'Colorado' },
  { value: 'connecticut', label: 'Connecticut' },
  { value: 'district_of_columbia', label: 'District of Columbia' },
  { value: 'delaware', label: 'Delaware' },
  { value: 'florida', label: 'Florida' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'hawaii', label: 'Hawaii' },
  { value: 'iowa', label: 'Iowa' },
  { value: 'idaho', label: 'Idaho' },
  { value: 'illinois', label: 'Illinois' },
  { value: 'indiana', label: 'Indiana' },
  { value: 'kansas', label: 'Kansas' },
  { value: 'kentucky', label: 'Kentucky' },
  { value: 'louisiana', label: 'Louisiana' },
  { value: 'massachusetts', label: 'Massachusetts' },
  { value: 'maryland', label: 'Maryland' },
  { value: 'maine', label: 'Maine' },
  { value: 'michigan', label: 'Michigan' },
  { value: 'minnesota', label: 'Minnesota' },
  { value: 'missouri', label: 'Missouri' },
  { value: 'mississippi', label: 'Mississippi' },
  { value: 'montana', label: 'Montana' },
  { value: 'north_carolina', label: 'North Carolina' },
  { value: 'north_dakota', label: 'North Dakota' },
  { value: 'nebraska', label: 'Nebraska' },
  { value: 'new_hampshire', label: 'New Hampshire' },
  { value: 'new_jersey', label: 'New Jersey' },
  { value: 'new_mexico', label: 'New Mexico' },
  { value: 'nevada', label: 'Nevada' },
  { value: 'new_york', label: 'New York' },
  { value: 'ohio', label: 'Ohio' },
  { value: 'oklahoma', label: 'Oklahoma' },
  { value: 'oregon', label: 'Oregon' },
  { value: 'pennsylvania', label: 'Pennsylvania' },
  { value: 'puerto_rico', label: 'Puerto Rico' },
  { value: 'rhode_island', label: 'Rhode Island' },
  { value: 'south_carolina', label: 'South Carolina' },
  { value: 'south_dakota', label: 'South Dakota' },
  { value: 'tennessee', label: 'Tennessee' },
  { value: 'texas', label: 'Texas' },
  { value: 'utah', label: 'Utah' },
  { value: 'virginia', label: 'Virginia' },
  { value: 'vermont', label: 'Vermont' },
  { value: 'washington', label: 'Washington' },
  { value: 'wisconsin', label: 'Wisconsin' },
  { value: 'west_virginia', label: 'West Virginia' },
  { value: 'wyoming', label: 'Wyoming' }
];

const getLocationData = zip => zipcodes.lookup(zip);

export const validateCodeLocation = (fieldValue = '') => {
  // Only validate postal code if raffle has strict location
  if (raffle.strictLocation && raffle.strictLocation !== 'no_restrictions') {
    const validLocation = raffle.strictLocation;
    const locationData = getLocationData(fieldValue) || { country: '', state: '' };
    return (
      locationData.state
        .toLowerCase()
        .split(' ')
        .join('_') === validLocation || locationData.country.toLowerCase() === validLocation
    );
  }

  return true;
};

export const validateCodeFormat = fieldValue => {
  const validCanadianCode = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/.test(fieldValue);
  const validAmericanCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(fieldValue);
  return validCanadianCode || validAmericanCode;
};

export const trimCustomizableStr = (str, strLength = 20) => {
  const maxStrLength = strLength;
  if (!str) {
    return null;
  }

  if (str.length <= maxStrLength) {
    return str;
  }

  return `${str.slice(0, maxStrLength)}...`;
};

export const validateProvinceLocation = () => true;
// COMMENTED OUT UNTIL SASKATCHEWAN IS NO LONGER HARDCODED
// export const validateProvinceLocation = (fieldValue = '') => {
//   // Only validate province if raffle has strict location
//   if (raffle.strictLocation && raffle.strictLocation !== 'no_restrictions') {
//     const stateListValues = states.map(({ value }) => value)
//     const USA = 'us'
//     const validLocation = raffle.strictLocation;
//     return validLocation === USA ?
//       stateListValues.includes(fieldValue) :
//       fieldValue === validLocation
//   }

//   return true;
// };
