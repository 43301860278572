// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-winners-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/winners.js" /* webpackChunkName: "component---src-templates-winners-js" */),
  "component---src-templates-media-page-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-grand-prize-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/grand-prize.js" /* webpackChunkName: "component---src-templates-grand-prize-js" */),
  "component---src-templates-category-prizes-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/category-prizes.js" /* webpackChunkName: "component---src-templates-category-prizes-js" */),
  "component---src-templates-addon-cash-calendar-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/addon-cash_calendar.js" /* webpackChunkName: "component---src-templates-addon-cash-calendar-js" */),
  "component---src-templates-addon-fifty-fifty-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/addon-fifty_fifty.js" /* webpackChunkName: "component---src-templates-addon-fifty-fifty-js" */),
  "component---src-templates-listing-prizes-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/listing-prizes.js" /* webpackChunkName: "component---src-templates-listing-prizes-js" */),
  "component---src-templates-proceeds-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/templates/proceeds.js" /* webpackChunkName: "component---src-templates-proceeds-js" */),
  "component---src-pages-404-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-flow-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/pages/buy-flow.js" /* webpackChunkName: "component---src-pages-buy-flow-js" */),
  "component---src-pages-index-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-success-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-photo-gallery-js": () => import("/app/tmp/static_site/kmb_test_raffle_2019_staging/src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/app/tmp/static_site/kmb_test_raffle_2019_staging/.cache/data.json")

