import {
  SET_ORDER,
  SET_ORDER_SUCCESS,
  SET_ORDER_ENDPOINT,
  TOGGLE_DISPLAY_ORDER,
  SET_PURCHASER_INFO,
  SET_TRANSACTION_ID,
  SET_UPGRADE_MAP,
  SET_PAYMENT_ENDPOINT,
  SET_VERIFIED_ORDER,
  DISPLAY_CANNOT_PROGRESS,
  ADD_SUBSCRIPTIONS_TO_ORDER
} from './types';

export const setOrder = (order, setSubs = false) => ({
  type: SET_ORDER,
  payload: { order, setSubs }
});

export const setOrderSuccess = boolean => ({ type: SET_ORDER_SUCCESS, payload: boolean });
export const setOrderEndpoint = endpoint => ({ type: SET_ORDER_ENDPOINT, payload: endpoint });
export const toggleDisplayOrder = () => ({ type: TOGGLE_DISPLAY_ORDER });
export const setPurchaserInfo = purchaserInfo => ({
  type: SET_PURCHASER_INFO,
  payload: purchaserInfo
});
export const setTransactionId = transactionId => ({
  type: SET_TRANSACTION_ID,
  payload: transactionId
});
export const setUpgradeMap = upgradeMap => ({ type: SET_UPGRADE_MAP, payload: upgradeMap });
export const setPaymentEndpoint = endpoint => ({ type: SET_PAYMENT_ENDPOINT, payload: endpoint });
export const setVerifiedOrder = verifiedOrder => ({
  type: SET_VERIFIED_ORDER,
  payload: verifiedOrder
});
export const displayCannotProgress = boolean => ({
  type: DISPLAY_CANNOT_PROGRESS,
  payload: boolean
});
export const addSubscriptionsToOrder = boolean => ({
  type: ADD_SUBSCRIPTIONS_TO_ORDER,
  payload: boolean
});
