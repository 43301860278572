import { fromJS } from 'immutable';
import { raffle } from 'data/data.json';
import {
  SET_ORDER,
  SET_ORDER_SUCCESS,
  SET_ORDER_ENDPOINT,
  TOGGLE_DISPLAY_ORDER,
  SET_PURCHASER_INFO,
  SET_TRANSACTION_ID,
  SET_UPGRADE_MAP,
  SET_PAYMENT_ENDPOINT,
  SET_VERIFIED_ORDER,
  DISPLAY_CANNOT_PROGRESS,
  ADD_SUBSCRIPTIONS_TO_ORDER
} from './types';

const {
  buyFlowData: { attribs },
  packTypes,
  drawTypes,
  waitlistEnabled
} = raffle;

const initialState = {
  order: fromJS(packTypes).map(pack => pack.set('ordered', 0)),
  draws: fromJS(drawTypes),
  subscriptions: fromJS(packTypes).map(pack => pack.set('ordered', 0)),
  subscriptionsAddedToOrder: false,
  displayOrder: true,
  attributes: fromJS(attribs),
  cannotProgressDisplayed: false,
  waitlistEnabled: waitlistEnabled || false,
  upgradeMap: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER: {
      const { order, setSubs } = payload;
      const { subscriptionsAddedToOrder, subscriptions } = state;
      return !setSubs
        ? {
            ...state,
            order,
            subscriptions: !subscriptionsAddedToOrder ? order : subscriptions
          }
        : { ...state, subscriptions: order };
    }
    case SET_ORDER_SUCCESS:
      return { ...state, orderSuccessful: payload };
    case SET_ORDER_ENDPOINT:
      return { ...state, orderEndpoint: payload };
    case TOGGLE_DISPLAY_ORDER:
      return { ...state, displayOrder: !state.displayOrder };
    case SET_PURCHASER_INFO:
      return { ...state, purchaserInfo: payload };
    case SET_TRANSACTION_ID:
      return { ...state, transactionId: payload };
    case SET_UPGRADE_MAP:
      return { ...state, upgradeMap: payload };
    case SET_PAYMENT_ENDPOINT:
      return { ...state, paymentEndpoint: payload };
    case SET_VERIFIED_ORDER:
      return { ...state, verifiedOrder: payload };
    case DISPLAY_CANNOT_PROGRESS:
      return { ...state, cannotProgressDisplayed: payload };
    case ADD_SUBSCRIPTIONS_TO_ORDER:
      return { ...state, subscriptionsAddedToOrder: payload };
    default:
      return state;
  }
};

export default reducer;
