export const ticketStatus = {
  SOLD_OUT: 'S',
  AVAILABLE: 'A',
  WAITLISTED: 'W'
};

export const prizeTypes = {
  EARLY_BIRD: 'early_bird_prize',
  GRAND_PRIZE: 'grand_prize'
};
