import {
  TOGGLE_ORDER_MODAL,
  TOGGLE_CONTACT_MODAL,
  SET_CONTACT_MODAL_CONTENT,
  ADD_ALERT,
  CLOSE_ALERT,
  TOGGLE_MEMBERSHIP_MODAL,
  TOGGLE_SUBSCRIPTIONS_MODAL
} from './types';

const initialState = {
  displayOrderModal: false,
  displayContactModal: false,
  displayMembershipModal: false,
  displaySubscriptionsModal: false,
  contactModalContent: 'DEFAULT',
  alerts: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ALERT:
      return {
        ...state,
        alerts: state.alerts.concat([payload])
      };

    case CLOSE_ALERT: {
      const { alerts } = state;

      return {
        ...state,
        alerts: alerts.filter((alert, alertIndex) => alertIndex !== payload.index)
      };
    }

    case TOGGLE_ORDER_MODAL:
      return {
        ...state,
        displayOrderModal: !state.displayOrderModal
      };

    case TOGGLE_CONTACT_MODAL:
      return { ...state, displayContactModal: !state.displayContactModal };
    case TOGGLE_MEMBERSHIP_MODAL:
      return { ...state, displayMembershipModal: !state.displayMembershipModal };
    case TOGGLE_SUBSCRIPTIONS_MODAL:
      return { ...state, displaySubscriptionsModal: !state.displaySubscriptionsModal };
    case SET_CONTACT_MODAL_CONTENT:
      return { ...state, contactModalContent: payload };
    default:
      return state;
  }
};

export default reducer;
