import { createSelector } from 'reselect';

import { hasOrderedWaitlistedPacks, isMonoPack, getTotalTicketsByDrawId } from 'utils/tickets';

export const checkIfWaitlisted = duck =>
  hasOrderedWaitlistedPacks(duck.order) && duck.waitlistEnabled;

/* Calculating Multi/Max/Mega Packs that are of best value */

const getMonoPacks = state =>
  state.order.filter(p => isMonoPack(p)).sort((a, b) => b.get('price') - a.get('price'));

const getNonMonoPacks = state => state.order.filter(p => !isMonoPack(p));

export const calculateBestValuePacks = createSelector(
  getMonoPacks,
  getNonMonoPacks,
  (monoPacks, nonMonoPacks) =>
    nonMonoPacks.filter(nonMonoPack => {
      const totalPackPrice = nonMonoPack.get('price');
      const packDrawTypes = nonMonoPack.get('packDrawTypes');

      const totalDrawPrice = packDrawTypes.reduce((totalPriceOfDrawContents, draw) => {
        let drawQuantity = draw.get('quantity');
        return (
          totalPriceOfDrawContents +
          monoPacks.reduce((totalPriceOfDraw, monoPack) => {
            if (monoPack.getIn(['packDrawTypes', 0, 'code']) === draw.get('code')) {
              const monoPackQuantity = monoPack.getIn(['packDrawTypes', 0, 'quantity']);

              while (monoPackQuantity <= drawQuantity && drawQuantity !== 0) {
                if (drawQuantity - monoPackQuantity >= 0) {
                  drawQuantity -= monoPackQuantity;
                  return totalPriceOfDraw + monoPack.get('price');
                }
              }
            }
            return totalPriceOfDraw;
          }, 0)
        );
      }, 0);
      return totalPackPrice < totalDrawPrice;
    })
);

/* Getting Draw Dependancies */

const getDraws = state => state.orderingState.draws;

const getOrder = state =>
  state.modalState.displaySubscriptionsModal === true
    ? state.orderingState.subscriptions
    : state.orderingState.order;

export const getDependancies = (draws, order) => {
  const orderHasRequiredTickets = order.find(pk => pk.getIn(['packDrawTypes', 0, 'dependsOn']));

  return order.reduce((dependantPacks, pack) => {
    if (!isMonoPack(pack) || pack.get('ordered') === 0) {
      return dependantPacks;
    }

    const dependsOn =
      orderHasRequiredTickets && isMonoPack(pack)
        ? pack.getIn(['packDrawTypes', 0, 'dependsOn'])
        : draws.getIn([0, 'code']);

    const drawPack = draws.find(draw => draw.get('code') === dependsOn);

    if (drawPack && getTotalTicketsByDrawId(order, dependsOn) === 0) {
      dependantPacks.push(drawPack);
      return dependantPacks;
    }

    return dependantPacks;
  }, []);
};

export const checkOrderedPacksForDependancies = createSelector(
  getDraws,
  getOrder,
  getDependancies
);
