import {
  TOGGLE_ORDER_MODAL,
  TOGGLE_CONTACT_MODAL,
  CLOSE_ALERT,
  ADD_ALERT,
  TOGGLE_MEMBERSHIP_MODAL,
  TOGGLE_SUBSCRIPTIONS_MODAL,
  SET_CONTACT_MODAL_CONTENT
} from './types';

export const toggleOrderModal = () => ({ type: TOGGLE_ORDER_MODAL });
export const toggleContactModal = () => ({ type: TOGGLE_CONTACT_MODAL });
export const closeAlert = payload => ({ type: CLOSE_ALERT, payload });
export const addAlert = payload => ({ type: ADD_ALERT, payload });
export const setContactModalContent = content => ({
  type: SET_CONTACT_MODAL_CONTENT,
  payload: content
});
export const toggleMembershipModal = () => ({ type: TOGGLE_MEMBERSHIP_MODAL });
export const toggleSubscriptionsModal = () => ({ type: TOGGLE_SUBSCRIPTIONS_MODAL });
